var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "vx-navbar-wrapper navbar-full p-5" },
          [
            _c(
              "vs-navbar",
              {
                staticClass: "navbar-custom navbar-skelton",
                class: "items-end",
              },
              [
                _c("vs-spacer"),
                _c("CartDropDown", {
                  attrs: { cartItems: _vm.cart.items },
                  on: {
                    updateCartHandling: _vm.updateCartHandling,
                    paymentPurchasePopup: function ($event) {
                      _vm.showPaymentPopup = true
                    },
                  },
                }),
                _c("CartResumeSideBar", {
                  attrs: { cartItems: _vm.cart.items },
                  on: { updateCartHandling: _vm.updateCartHandling },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "m-5" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                title: _vm.$t("marketing_place.title", [_vm.institutionName]),
              },
            },
            [
              _c("ProductGridCardView", {
                attrs: {
                  encrypParam: _vm.encrypParam,
                  validatedExistProductAdd: _vm.validatedProductFromCart,
                },
                on: { updateCartHandling: _vm.updateCartHandling },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("shopping-checkout", {
        attrs: { cartItems: _vm.cart.items, visible: _vm.showPaymentPopup },
        on: {
          "update:visible": function ($event) {
            _vm.showPaymentPopup = $event
          },
          closeShoppingCheckoutPopup: function ($event) {
            _vm.showPaymentPopup = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }